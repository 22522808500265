import { FC } from 'react';
import Image from 'next/image';

import useToggleModal from '@/hooks/useToggleModal';
import Heading from '@/components/common/Heading';
import Button from '@/components/common/Button/Button';
import FormModal from '@/components/FormModal';
import ClientOnly from '@/components/common/ClientOnly';

interface IHeroProps {}

const Hero: FC<IHeroProps> = () => {
  const { isOpen, openModal, closeModal, handleKeyDown, handleBackdropClick } =
    useToggleModal();
  return (
    <>
      <div className=" md:flex items-center  ">
        <div className="  flex flex-col md:flex-1 max-md:py-[30px] mb-5 md:mb-0">
          <p
            className=" mb-[1.5rem] 
            animate-text bg-gradient-to-r from-[--accent-color] via-[#7928CA] to-[#FF4D4D] bg-clip-text text-transparent text-4xl leading-[2.8rem] md:text-6xl font-extrabold lg:leading-[1.2em] lg:mb-[0.5em]
              "
          >
            Transform your vision into digital reality
          </p>
          <Heading
            headingLevel="h1"
            className=" custom-p mb-[1.5em] font-medium text-[1.5em] lg:text-[1.5em] lg:w-[80%] leading-[1.3] text-black "
          >
            Web design, web development and digital marketing solutions agency.
          </Heading>
          <Button onClick={openModal} className="mr-auto">
            Start Project
          </Button>
        </div>
        <div className="md:flex-1">
          <Image
            className=" w-[100%] lg:w-[80%] h-auto m-auto"
            src={'/assets/icons/heroImg/main-page.svg'}
            width={500}
            height={500}
            alt="Hero Image"
            priority
          />
        </div>
      </div>
      <ClientOnly>
        {isOpen && (
          <FormModal
            handleKeyDown={handleKeyDown}
            handleBackdropClick={handleBackdropClick}
            closeModal={closeModal}
          />
        )}
      </ClientOnly>
    </>
  );
};

export default Hero;
