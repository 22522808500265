import Image from 'next/image';
import { FC, HtmlHTMLAttributes } from 'react';

interface ITechnologyItemProps extends HtmlHTMLAttributes<HTMLImageElement> {
  technologyName: string;
  technologyURL: string;
}

const TechnologyItem: FC<ITechnologyItemProps> = ({
  technologyName,
  technologyURL,
  className,
}) => {
  return (
    <>
      <Image
        src={`/assets/icons/technology/${technologyURL}`}
        alt={technologyName}
        width={500}
        height={500}
        className={className}
      />
    </>
  );
};

export default TechnologyItem;
