import { Metadata } from 'next';
// import { FC } from 'react';
import Script from 'next/script';
import useMatchMedia from '@/hooks/useMatchMedia';

//Custom component's
import ClientOnly from '@/components/common/ClientOnly';
import Container from '@/components/common/Container';
import Section from '@/components/common/Section';
import Background from '@/components/common/Background';
import Heading from '@/components/common/Heading';
import BackgroundHeroImg from '@/components/common/Background/BackgroundHeroImg/';
import BackgroundHero from '@/components/common/Background/BackgroundHero';
import Box from '@/components/common/Box';

import Hero from '@/components/Hero';
import Feature from '@/components/Pages/Index/Features';
import Skills from '@/components/Pages/Index/Skills';
import Technology from '@/components/Pages/Index/Technology';
import Info from '@/components/Pages/Index/Info';
import FAQs from '@/components/Pages/Index/FAQs';
import Consultation from '@/components/Pages/Index/Сonsultation';
import Portfolio from '@/components/Pages/Index/Portfolio';

//JSON file's
import features from '@/db/index/features.json';
import faqs from '@/db/index/faqsIndex.json';
import info from '@/db/index/info.json';
import skills from '@/db/index/skills.json';
import ss_index from '@/db/seoscript/ss_index.json';

export const metadata: Metadata = {
  title: 'code-solution',
  description: 'Services code solutions',
};

const Home = () => {
  const { isMobile } = useMatchMedia();

  return (
    <>
      <Box className=" relative">
        <Container>
          <Section className="pt-0 pb-0 lg:pb-4 ">
            <Hero />
          </Section>
        </Container>
        <BackgroundHero />
        <BackgroundHeroImg />
      </Box>

      <Background className="bg-[#f5f5f7]">
        <Container>
          <Section>
            <Heading headingLevel="h2" className="text-center mb-5 md:mb-16 ">
              Solutions for Your Business
            </Heading>
            <Feature features={features} />
          </Section>
        </Container>
      </Background>

      <Background className="">
        <Container>
          <Section className=" px-0 md:px-[50px]">
            <Heading headingLevel="h2" className="text-center mb-5 md:mb-16 ">
              Benefits of Choosing CodeSolution
            </Heading>
            <Skills skills={skills} />
          </Section>
        </Container>
      </Background>

      <Box className="bg-gradient-[333deg] from-[#DEE9FF]  to-[#E6F6FE]">
        <Container>
          <Section className=" px-0 md:px-[50px]">
            <Heading headingLevel="h2" className="text-center mb-5 md:mb-16">
              <ClientOnly>
                {isMobile
                  ? 'Future-proof technologies!'
                  : 'Work With Future-Proof Technologies'}
              </ClientOnly>
            </Heading>
            <Technology />
          </Section>
        </Container>
      </Box>

      <Background className="">
        <Container>
          <Section className="px-0 md:px-[50px]">
            <Heading headingLevel="h2" className=" text-center mb-5 md:mb-16">
              Discover the CodeSolution
            </Heading>
            <Info info={info} />
          </Section>
        </Container>
      </Background>

      <Background className="">
        <Container>
          <Section className="px-0 md:px-[50px]">
            <Heading headingLevel="h2" className="text-center mb-5 md:mb-16 ">
              Explore Our Projects
            </Heading>
            <Portfolio />
          </Section>
        </Container>
      </Background>

      <Background className="">
        <Container>
          <Section>
            <Heading headingLevel="h2" className="text-center mb-5 md:mb-16">
              <ClientOnly>
                {isMobile ? 'FAQs' : 'Frequently Asked Questions'}
              </ClientOnly>
            </Heading>
            <FAQs faqs={faqs} />
          </Section>
        </Container>
      </Background>

      <Background className="">
        <Container>
          <Section className="lg:pb-[50px]">
            <Heading headingLevel="h2" className="visually-hidden">
              Consultation by project!
            </Heading>
            <Consultation />
          </Section>
        </Container>
      </Background>

      <Script
        id="ss_index"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ss_index) }}
      />
    </>
  );
};

export default Home;
