import { FC } from 'react';
import useToggleModal from '@/hooks/useToggleModal';

import Button from '@/components/common/Button';
import Heading from '@/components/common/Heading';
import FormModal from '@/components/FormModal';
import ClientOnly from '@/components/common/ClientOnly';

interface IConsultationProps {}

const Consultation: FC<IConsultationProps> = () => {
  const { isOpen, openModal, closeModal, handleKeyDown, handleBackdropClick } =
    useToggleModal();
  return (
    <>
      <div
        className="card-set max-md:justify-start justify-center items-center   lg:justify-around p-5  md:px-0 md:py-5 lg:py-[70px] rounded-[15px]
      bg-gradient-to-r from-[#d2e6f6] to-[#b7dfea]"
      >
        <div className="card-set-item flex flex-col gap-5 ">
          <Heading
            headingLevel="h2"
            className="  lg:text-left text-[2.5em] mb-[0.3em]"
          >
            Want to chat about your dream project?
          </Heading>
          <p className=" custom-p text-[1.3em]  lg:text-left">
            Schedule a free project consultation with one of our experts today.
          </p>
        </div>
        <div>
          <Button onClick={openModal} className="mt-[1.5em] lg:mt-0">
            Request A Quote
          </Button>
        </div>
      </div>
      <ClientOnly>
        {isOpen && (
          <FormModal
            handleKeyDown={handleKeyDown}
            handleBackdropClick={handleBackdropClick}
            closeModal={closeModal}
          />
        )}
      </ClientOnly>
    </>
  );
};

export default Consultation;
