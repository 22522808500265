import { FC } from 'react';
import PortfolioItem from './PortfolioItem';
import { PortfolioType } from '@/types';
import portfolio from '@/db/index/portfolio.json';

interface IPortfolioProps {}

const Portfolio: FC<IPortfolioProps> = () => {
  return (
    <>
      <ul className="card-set [--card-gap:70px]">
        {portfolio.map(
          ({ id, img, title, text, secondText }: PortfolioType) => (
            <li
              key={id}
              className="card-set-item md:[--card-item:2] md:max-w-[354px] lg:[--card-item:3] lg:max-w[370px] "
            >
              <PortfolioItem
                img={img}
                title={title}
                text={text}
                secondText={secondText}
              />
            </li>
          )
        )}
      </ul>
    </>
  );
};

export default Portfolio;
