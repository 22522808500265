import { FC } from 'react';
import Image from 'next/image';
import componentUtils from '@/utils/componentUtils';

interface IInfoItemProps {
  infoText?: string[];
  infoSubMenu?: string[];
  infoImg: string;
  infoShadow?: string;
  index: number;
}

const InfoItem: FC<IInfoItemProps> = ({
  infoText,
  infoSubMenu,
  infoImg,
  index,
}) => {
  return (
    <li className="lg:flex justify-between even:flex-row-reverse gap-[2em]">
      <div className="lg:flex flex-col   justify-center  lg:w-[50%] ">
        {infoText && (
          <div>
            {infoText.map((i, id) => (
              <p key={id} className={` mb-4 custom-p   lg:mx-[10%]`}>
                {i}
              </p>
            ))}
          </div>
        )}
        {infoSubMenu && (
          <ul className=" marker:text-black list-decimal m-0 px-[10%]">
            {infoSubMenu.map((i, id) => (
              <li key={id}>
                <p className={` mb-4 custom-p   `}>{i}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className=" lg:w-[50%] aspect-[4/3] lg:flex justify-center items-center ">
        <Image
          style={{ boxShadow: `${componentUtils.InfoImageShadow(index)}` }}
          className={`block rounded-xl xl:w-[80%] h-auto`}
          src={`/assets/icons/info/${infoImg}`}
          width={500}
          height={500}
          alt={`${infoImg}`}
        />
      </div>
    </li>
  );
};

export default InfoItem;
