import { FC, ReactNode, HTMLAttributes } from 'react';

interface IBackgroundProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const Background: FC<IBackgroundProps> = ({ children, className }) => {
  return (
    <>
      <div className={['', className].join(' ')}>{children}</div>
    </>
  );
};

export default Background;
