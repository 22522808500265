import { FC } from 'react';
import InfoItem from './InfoItem';

import { InfoType } from '@/types';

interface IInfoProps {
  info: InfoType[];
}

const Info: FC<IInfoProps> = ({ info }) => {
  return (
    <>
      <ul className="max-lg:[&>*:not(:last-child)]:mb-7">
        {info.map(
          (
            { id, infoText, infoSubMenu, infoImg, infoShadow }: InfoType,
            idx
          ) => (
            <InfoItem
              key={id}
              index={idx}
              infoText={infoText}
              infoSubMenu={infoSubMenu}
              infoImg={infoImg}
              infoShadow={infoShadow}
            />
          )
        )}
      </ul>
    </>
  );
};

export default Info;
