import { FC, ReactNode, HTMLAttributes } from 'react';

interface ISectionProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode;
}

const Section: FC<ISectionProps> = ({ children, className }) => {
  return (
    <>
      <section className={['py-8', className].join(' ')}>{children}</section>
    </>
  );
};

export default Section;
