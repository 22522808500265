import { FC } from 'react';
import TechnologyItem from './TechnologyItem';
import technology from '@/db/index/technology.json';

interface ITechnologyProps {}

const Technology: FC<ITechnologyProps> = () => {
  return (
    <>
      <ul
        className="card-set justify-center items-center  
    [--card-item:5] [--card-gap:20px] 
    md:[--card-item:8]  md:[--card-gap:30px] 
    lg:[--card-gap:50px] xl:[--card-gap:80px]"
      >
        {technology.map(({ id, technologyName, technologyURL }) => (
          <li key={id} className="card-set-item">
            <TechnologyItem
              className=""
              technologyURL={technologyURL}
              technologyName={technologyName}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default Technology;
