import { FC } from 'react';
import Link from 'next/link';

import Heading from '@/components/common/Heading';
import Image from 'next/image';
// import Link from 'next/link';

interface IFeaturesItemProps {
  featureIdText: string;
  featureIcon: string;
  featureTittle: string;
  featureText: string;
  featureButtonText: string;
  featureLink: string;
  featureAriaLabel: string;
}

const FeaturesItem: FC<IFeaturesItemProps> = ({
  featureIdText,
  featureIcon,
  featureTittle,
  featureText,
  featureButtonText,
  featureLink,
  featureAriaLabel,
}) => {
  return (
    <div className=" group block relative p-5 md:px-[35px]  md:py-[25px] rounded-2xl bg-white  shadow-[0_0_10px_-8px_#000] hover:shadow-[2px_4px_14px_#00000016] hover:transform hover:scale-x-[1.01] hover:scale-y-[1.01] hover:scale-z-[1.01] duration-[--duration] ">
      <Image
        className=" w-32 relative top-0  mb-3"
        src={`/assets/icons/features/${featureIcon}`}
        width={100}
        height={100}
        alt={`${featureTittle}`}
      />
      <span className=" absolute right-[30px] top-[20px]  text-[#EAEAEA] text-[60px] text-transparent  text-stroke-width ">
        {featureIdText}
      </span>
      <Heading headingLevel="h3" className=" h-[70px] ">
        {featureTittle}
      </Heading>
      <p className="md:h-[220px] lg:h-[240px] xl:h-[160px]  mb-[20px]  custom-p">
        {featureText}
      </p>
      <Link
        href={`/services/${featureLink}`}
        className="flex justify-center py-[10px] px-[20px] text-base rounded-[15px] font-light text-[#000] border-transparent bg-[#e8e8ed] hover:bg-[#dfdfe2]  w-[170px]  cursor-pointer"
        aria-label={featureAriaLabel}
      >
        <span className="visually-hidden">{featureAriaLabel}</span>
        {featureButtonText}
      </Link>
    </div>
  );
};

export default FeaturesItem;
