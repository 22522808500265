import { FC } from 'react';
import FeaturesItem from './FeaturesItem';
import { FeatureType } from '@/types';

interface IFeatureProps {
  features: FeatureType[];
}

const Feature: FC<IFeatureProps> = ({ features }) => {
  return (
    <>
      <ul className={'card-set [--card-gap:30px]'}>
        {features.map(
          ({
            featureId,
            featureIdText,
            featureIcon,
            featureTittle,
            featureText,
            featureButtonText,
            featureLink,
            featureAriaLabel,
          }: FeatureType) => (
            <li
              key={featureId}
              className={
                'card-set-item md:[--card-item:2] lg:[--card-item:3]  w-full  '
              }
            >
              <FeaturesItem
                featureIdText={featureIdText}
                featureIcon={featureIcon}
                featureTittle={featureTittle}
                featureText={featureText}
                featureButtonText={featureButtonText}
                featureLink={featureLink}
                featureAriaLabel={featureAriaLabel}
              />
            </li>
          )
        )}
      </ul>
    </>
  );
};

export default Feature;
