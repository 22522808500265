import { FC, HTMLAttributes } from 'react';
import Image from 'next/image';

interface IBackgroundHeroImgProps extends HTMLAttributes<HTMLDivElement> {}

const BackgroundHeroImg: FC<IBackgroundHeroImgProps> = ({ className }) => {
  return (
    <>
      <div className={['max-md:visually-hidden', className].join(' ')}>
        <Image
          className=" absolute bottom-0 w-[100%] h-auto  -z-10 "
          src={'/herobgfirst.webp'}
          width={500}
          height={500}
          alt="Hero Image"
          priority
        />
      </div>
    </>
  );
};

export default BackgroundHeroImg;
