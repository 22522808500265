import { FC, ReactNode, HTMLAttributes } from 'react';

interface IBackgroundHeroProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

const BackgroundHero: FC<IBackgroundHeroProps> = ({ children, className }) => {
  return (
    <>
      <div
        className={[
          'absolute -z-[10] top-0 left-0 w-full h-full bg-gradient-[38deg] from-[#f5f7fe] from-[28%] to-[#f7fcff] to-72%',
          className,
        ].join(' ')}
      >
        {children}
      </div>
    </>
  );
};

export default BackgroundHero;
