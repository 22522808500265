import { FC } from 'react';
import SkillsItem from './SkillsItem';

import { SkillsType } from '@/types';

interface ISkillsProps {
  skills: SkillsType[];
}

const Skills: FC<ISkillsProps> = ({ skills }) => {
  return (
    <>
      <ul className="card-set [--card-gap:30px] justify-around ">
        {skills.map(({ id, skillsTitle, skillsText }: SkillsType) => (
          <SkillsItem
            key={id}
            skillsTitle={skillsTitle}
            skillsText={skillsText}
          />
        ))}
      </ul>
    </>
  );
};

export default Skills;
