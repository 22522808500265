import { FC } from 'react';
import Image from 'next/image';
import Heading from '@/components/common/Heading';

interface IPortfolioItemProps {
  img: string;
  title: string;
  text: string;
  secondText: string;
}

const PortfolioItem: FC<IPortfolioItemProps> = ({
  img,
  title,
  text,
  secondText,
}) => {
  return (
    <>
      <div className="group flex flex-col h-full  rounded-lg overflow-hidden bg-white shadow-[0_5px_10px_rgba(0,0,0,.12)] hover:shadow-[0_8px_30px_rgba(0,0,0,.12)] duration-[--duration]">
        <div className="relative overflow-hidden">
          <Image
            className=" "
            src={`/assets/icons/portfolio/${img}`}
            width={1000}
            height={1000}
            alt={`dev02`}
          />
          <div className="absolute bottom-0 right-0 w-full h-full translate-y-[100%] transition-transform group-hover:translate-y-[0] duration-[--duration] bg-gradient-[333deg] from-[#49659e] to-[#57b5e4] px-6 py-2 flex flex-col justify-center ">
            <p className=" custom-p  text-[--white-color]  text-[14px] leading-[1.2rem] lg:leading-[1.1rem] lg:text-[12px] xl:leading-[1.4rem] xl:text-base">
              {text}
            </p>
            <p className="custom-p text-[--white-color] mt-1 text-[14px] leading-[1.2rem] lg:leading-[1.1rem] lg:text-[12px] xl:leading-[1.4rem] xl:text-base ">
              {secondText}
            </p>
          </div>
        </div>
        <div className="grow px-5 py-5">
          <Heading headingLevel="h3" className="text-base ">
            {title}
          </Heading>
        </div>
      </div>
    </>
  );
};

export default PortfolioItem;
