const InfoImageShadow = (idx: number) => {
  let boxShadow: string = '';
  switch (idx) {
    case 0:
      boxShadow = '20px 30px #dde9e9';
      break;
    case 1:
      boxShadow = '-20px 30px #e9dddd';
      break;
    case 2:
      boxShadow = '20px 30px #dddee9';
      break;
    case 3:
      boxShadow = '-20px 30px #e9e3dd';
      break;
    default:
      boxShadow = 'none';
  }
  return boxShadow;
};

const componentUtils = {
  InfoImageShadow,
};

export default componentUtils;
