import { FC, HTMLAttributes, MouseEvent, useState, useRef } from 'react';
import { MdOutlineKeyboardArrowUp } from 'react-icons/md';

interface IFAQsItemProps extends HTMLAttributes<HTMLLIElement> {
  faqsData: string;
  faqsAnswer: string;
  faqsAnswerMenu?: string[];
  faqsQuestion: string;
}

const FAQsItem: FC<IFAQsItemProps> = ({
  faqsData,
  faqsQuestion,
  faqsAnswer,
  faqsAnswerMenu,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  const handleOpenFAQsItem = (
    e: MouseEvent<HTMLLIElement>,
    faqsData: string
  ) => {
    if (e.currentTarget.dataset.box === faqsData) {
      setIsOpen(!isOpen);
    }
  };

  const getContentHeight = () => {
    if (elementRef.current) {
      return elementRef.current.scrollHeight;
    }
    return;
  };

  const contentHeight = isOpen ? getContentHeight() : 0;

  return (
    <>
      <li
        className={[
          'w-full lg:w-[80%] pt-5  pr-5 mx-auto border-[#21212110] border-b-2 border-dotted cursor-pointer group',
          className,
        ].join(' ')}
        data-box={faqsData}
        onClick={e => handleOpenFAQsItem(e, faqsData)}
      >
        <div
          className={`flex relative items-center ${
            isOpen ? 'text-[--accent-color]' : 'text-[--faq-color]'
          }  font-semibold duration-[--duration]`}
        >
          <p className="max-md:leading-6 lg:pl-10 pr-4 font-semibold text-[1.3em]">
            {faqsQuestion}
          </p>
          <MdOutlineKeyboardArrowUp
            size={25}
            className={`absolute top-[50%] -translate-y-1/2 -right-3 -rotate-180 
            ${isOpen ? 'fill-[--accent-color]' : 'fill-[--faq-color]'}
            ${isOpen ? 'rotate-0' : ''}
            transition-transform duration-[--duration]
            `}
          />
        </div>
        <div
          style={{ height: `${contentHeight}px` }}
          className={`mt-[20px] ${
            isOpen ? 'mb-5' : 'mb-0'
          } overflow-hidden transition-[height] duration-[--duration]`}
        >
          <div
            className="pt-2 max-md:pl-2 pl-4 lg:pl-10 "
            data-content="faqsData"
            ref={elementRef}
          >
            <p className="custom-p font-normal">{faqsAnswer}</p>
            {faqsAnswerMenu && (
              <ul className=" marker:text-black list-decimal card-set [--card-gap:8px]">
                {faqsAnswerMenu.map((i, id) => (
                  <li key={id} className="card-item first:mt-2 ml-4">
                    <p className={`custom-p pl-4`}>{i}</p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </li>
    </>
  );
};

export default FAQsItem;
