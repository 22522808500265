import { FC } from 'react';
import FAQsItem from './FAQsItem/FAQsItem';

import { FAQsType } from '@/types';

interface IFAQsProps {
  faqs: FAQsType[];
}

const FAQs: FC<IFAQsProps> = ({ faqs }) => {
  return (
    <>
      <ul className="">
        {faqs.map(
          ({ faqsId, faqsAnswer, faqsAnswerMenu, faqsQuestion }: FAQsType) => (
            <FAQsItem
              key={faqsId}
              faqsData={faqsId}
              faqsQuestion={faqsQuestion}
              faqsAnswer={faqsAnswer}
              faqsAnswerMenu={faqsAnswerMenu}
            />
          )
        )}
      </ul>
    </>
  );
};

export default FAQs;
