import { FC } from 'react';
import Heading from '@/components/common/Heading';

interface ISkillsItemProps {
  skillsTitle: string;
  skillsText: string;
}

const SkillsItem: FC<ISkillsItemProps> = ({ skillsTitle, skillsText }) => {
  return (
    <li className="card-set-item md:[--card-item:2] xl:max-w-[480px] ">
      <Heading headingLevel="h3" className="pr-8 mb-[1.3em] ">
        {skillsTitle}
      </Heading>
      <p className="custom-p  mb-4 md:pr-8  ">{skillsText}</p>
    </li>
  );
};

export default SkillsItem;
